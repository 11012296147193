import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { AnchorLink } from "gatsby-plugin-anchor-links"

import Animate from "@components/animate"
import Link from "@components/link"
import { Icon, iconOptions } from "@components/icon"
import SVG from "@components/svg"

// -------------------------------------------------------- | styles

import {
  button_container,
  button,
  size_large,
  size_medium,
  size_small,
  theme_outline,
  theme_purple,
  theme_transparent,
  theme_with_arrow_and_shadow,
  theme_with_arrow_and_shadow_container,
  theme_with_arrow,
  theme_with_icon,
  theme_with_icon_container
} from "./styles.module.scss"

const themeOptions = {
  arrow: theme_with_arrow,
  arrow_and_shadow: theme_with_arrow_and_shadow,
  outline: theme_outline,
  purple: theme_purple,
  transparent: theme_transparent,
  with_icon: theme_with_icon
}

const sizeOptions = {
  small: size_small,
  medium: size_medium,
  large: size_large
}

// -------------------------------------------------------- | component

const Button = ({
  children,
  className,
  currentIndex,
  icon,
  label,
  onClick,
  size,
  theme,
  to,
  type,
  disabled
}) => {
  const containerClasses = classNames(button_container, "button-container", {
    [theme_with_arrow_and_shadow_container]: theme === "with_arrow_and_shadow",
    [theme_with_icon_container]: theme === "with_icon"
  })

  const classes = classNames(button, {
    [className]: className,
    [sizeOptions[size]]: theme !== "with_icon" && theme !== "arrow" && sizeOptions[size],
    [themeOptions[theme]]: themeOptions[theme]
  })

  const buttonContents = (
    <>
      <span>
        {theme === "with_icon" && <Icon name={icon} />}

        <em>{children || label}</em>
      </span>

      {size !== "small" && (theme === "outline" || theme === "purple" || theme === "white") && (
        <SVG name="angle-right" />
      )}

      {(theme === "with_icon" || theme === "arrow" || theme === "arrow_and_shadow") && (
        <span>
          <SVG name="chevron-circle-right" />
        </span>
      )}
    </>
  )

  let animate = "fade_in"
  let delay

  if (theme === "with_icon" || theme === "arrow_and_shadow") {
    animate = "fade_in_up"
    delay = currentIndex * 450
  }

  let buttonComponent

  if (onClick || type) {
    buttonComponent = (
      <Animate
        animate={animate}
        delay={delay}
        tag="button"
        className={classes}
        onClick={!disabled && onClick}
        type={type}
        disabled={disabled}
      >
        {buttonContents}
      </Animate>
    )
  }
  if (to !== undefined && to.includes("#")) {
    buttonComponent = (
      <>
        <AnchorLink to={to} stripHash>
          <Animate animate={animate} delay={delay} className={classes} tag="span" onClick={onClick}>
            {buttonContents}
          </Animate>
        </AnchorLink>
      </>
    )
  }
  if (to && !to.includes("#")) {
    buttonComponent = (
      <Link to={to} className={classNames(containerClasses)} onClick={!disabled && onClick}>
        <Animate animate={animate} delay={delay} className={classes} tag="span" disabled={disabled}>
          {buttonContents}
        </Animate>
      </Link>
    )
  }

  // -------------------------------------------------------- | Animation

  return buttonComponent
}

Button.propTypes = {
  /**
   * Additional classes on the link element.
   */
  className: PropTypes.string,
  /**
   * Specifies if the button is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Text rendered to the screen inside the button.
   */
  children: PropTypes.string,
  /**
   * Get the current index.
   */
  currentIndex: PropTypes.number,
  /**
   * Text rendered to the screen inside the button.
   */
  label: PropTypes.string,
  /**
   * Specifies a click Function and uses the button element
   */
  onClick: PropTypes.func,
  /**
   * Specifies the icon to use when theme equals with_icon
   *
   * See the icon component for the available options
   */
  icon: PropTypes.oneOf(Object.keys(iconOptions)),
  /**
   * Specifies the size of button
   */
  size: PropTypes.oneOf(Object.keys(sizeOptions)),
  /**
   * Specifies the theme of button
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions)),
  /**
   * The href attribute for the link rendered to the screen.
   */
  to: PropTypes.string,
  /**
   * Sets the type attribute on the `<button>` element when useButtonElement is true.
   */
  type: PropTypes.oneOf(["submit", "button"])
}

Button.defaultProps = {
  icon: "health",
  size: "large",
  theme: "purple"
}

export default Button
