import React from "react"

import withLocation from "@src/pages/search/withLocation"
import { categoryPath, humanize } from "@src/utils"

import Block from "@components/block"
import Breadcrumbs from "@components/breadcrumbs"
import Card from "@components/card"
import Container from "@components/container"
import Content from "@components/content"
import FilterSort, { useFilterSort } from "@components/filter-sort"
import Grid from "@components/grid"
import Layout from "@src/layout"
import Pagination, { usePagination } from "@components/pagination"

// ---------------------------------------------------------

const CategoryTemplate = ({ data, pageContext: ctx, search }) => {
  const { category, posts: getPosts, categories, contact } = data
  const per = Number(search.per) < 15 ? Number(search.per) : 15
  const displayGrid = (data.category?.theme || "grid").toLowerCase().includes("grid")
  const posts = getPosts.edges.map(({ node }) => node)
  const isCategory = ctx.__typename !== "ContentfulPostType"
  const isPressRelease = ctx.slug.includes("press-releases")

  const [gridRef, count, setCount, page, handlePage] = usePagination(
    posts.length,
    Number(search.page) || 1
  )
  const [filtered, handleFilter, handleSort] = useFilterSort(
    posts,
    per,
    handlePage,
    setCount,
    isCategory
  )

  const getResults = filtered[page - 1]

  const renderCards = () => {
    if (!getResults) {
      return null
    } else {
      return getResults.map((node) => {
        const cardProps = {
          heading: node.title,
          body: node.body,
          duration: node.duration,
          image_media: node?.image_media_card,
          url: node.post_path,
          theme: displayGrid ? "media" : "list_item",
          category: { label: node.category?.title, url: categoryPath(node?.category) },
          type: { label: node?.resource_type?.title, url: categoryPath(node?.resource_type) },
          wordCount: node.wordCount
        }
        return <Card key={node.slug} {...cardProps} />
      })
    }
  }

  const renderGrid = () => {
    return (
      <Grid layout="3" margin_bottom="64">
        {getResults && (
          <>
            {renderCards()}
            {renderCards().length % 3 == 0 ? "" : <div></div>}
          </>
        )}
      </Grid>
    )
  }

  const renderList = () => {
    return (
      <Grid layout="full_width" margin_bottom="36">
        {getResults && (
          <>
            {renderCards()}
            {renderCards().length % 3 == 0 ? "" : <div></div>}
          </>
        )}
      </Grid>
    )
  }

  // ------------------------------------- | Format Breadcrumbs

  let parent, parentUrl

  if (ctx.slug.includes("/")) {
    const pathSlug = ctx.slug.split("/")

    parent = humanize(pathSlug[0])
    parentUrl = pathSlug[0]
  }

  const links = [
    { label: parent, url: parentUrl ? `/${parentUrl}` : null },
    { label: ctx.title, url: categoryPath(ctx) }
  ]

  // ---------------------------------------------------------

  return (
    <Layout title={category.title} meta={category.meta_data} locationPath={categoryPath(category)}>
      <Container>
        <Breadcrumbs links={links} />
        <Grid layout={isPressRelease ? "2" : null} margin_bottom="36">
          <Content {...category} className={isPressRelease ? null : "split_header"} />
        </Grid>
      </Container>
      <span ref={gridRef}>
        <Container padding="medium">
          <FilterSort
            filterBy={categories.nodes}
            filterLabel={isCategory ? "Filter By Type" : "Filter By Category"}
            sort
            handleFilter={handleFilter}
            handleSort={handleSort}
          />
          {displayGrid && renderGrid()}
          {!displayGrid && renderList()}
          {count > per && (
            <Pagination
              currentPage={page}
              itemsPerPage={per}
              totalItems={count}
              onChange={handlePage}
            />
          )}
        </Container>
      </span>
      <Block {...contact} />
    </Layout>
  )
}

export default withLocation(CategoryTemplate)
