/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from "prop-types"

import Button from "@src/components/button"
import Card from "@src/components/card"
import Container from "@src/components/container"
import Link from "@components/link"
import Grid from "@src/components/grid"
import MediaObject from "@src/components/media-object"

import { component as LinkList, fixtures as LinkListFixtures } from "@components/link-list"
const { parseContentfulJSON, categoryPath } = require("@src/utils")

import { cta, divider, left, media_cards, right, lastest_post } from "./styles.module.scss"
import Animate from "@components/animate/component"
import Posts from "@static/posts.json"

export default function LatestPosts({ config, references }) {
  let cfg = parseContentfulJSON(config)

  const linkList = cfg.link_list ? cfg.link_list : LinkListFixtures.props
  const allCategories = Posts.categories
  const cfgCategories = (references || [])
    .filter((ref) => ref.__typename === "ContentfulCategory")
    .map((ref) => ref.slug)

  let nodes = allCategories.filter((category) => cfgCategories.includes(category.slug))

  if (nodes.length == 0) {
    nodes = Posts.posts
  } else {
    nodes = nodes.map((node) => node.posts).flat()
  }

  // Sort nodes by publish date since GQL doesn't support sorting cateories via child node values
  nodes = nodes.sort((a, b) => Date.parse(a.publishDate) - Date.parse(b.publishDate)).reverse()

  const renderCards = (start, end, TagName, Index) => {
    return nodes.slice(start, end).map((node) => {
      const cardProps = {
        heading: node?.title,
        body: node?.body,
        duration: node?.duration,
        image: TagName === MediaObject ? node?.image_media_object : null,
        image_media: TagName !== MediaObject ? node?.image_media_card : null,
        alt:
          TagName === MediaObject ? node?.image_media_object?.title : node?.image_media_card?.title,
        url: node?.post_path,
        theme: TagName === MediaObject ? "dark" : "media",
        category: { label: node?.category?.title, url: categoryPath(node?.category) },
        type: { label: node?.resource_type?.title, url: categoryPath(node?.resource_type) },
        wordCount: node?.wordCount
      }
      return <TagName key={`card-${node?.slug}`} currentIndex={Index} {...cardProps} />
    })
  }

  const id = cfg?.title?.replace(/\s+/g, "-").replace(/&+/g, "and").toLowerCase()

  return (
    <>
      <Container
        className={lastest_post}
        padding={cfg.padding}
        theme={cfg.theme}
        id={id}
        showAnimation={cfg?.showAnimation}
      >
        <Grid gutter="none" layout="6040">
          <Animate className={left} animate="fade_in" delay={100}>
            {cfg.title && <h2>{cfg.title}</h2>}
            {cfg.subtitle && <p>{cfg.subtitle}</p>}
          </Animate>
          <div className={right}>
            <div>
              {cfg.cta && cfg.layout != "with_media_cards" && (
                <Button size="small" to={cfg.cta.url}>
                  {cfg.cta.label}
                </Button>
              )}
            </div>
          </div>
        </Grid>
        <Animate className={left} animate="fade_in" delay={550}>
          <div className={divider} />
        </Animate>
        <Grid gutter="medium" bypassAnimation>
          {cfg.layout == "with_media_cards" && (
            <>
              {renderCards(0, 1, Card, 1)}
              {renderCards(1, 2, Card, 2)}
              <div>
                <div className={media_cards}>
                  {renderCards(2, 3, MediaObject, 3)}
                  {renderCards(3, 4, MediaObject, 5)}
                  {renderCards(4, 5, MediaObject, 6)}
                </div>
                {cfg.cta && (
                  <Animate animate="fade_in_down" delay={300 * 7}>
                    <Link className={cta} to={cfg.cta.url}>
                      {cfg.cta.label}
                    </Link>
                  </Animate>
                )}
              </div>
            </>
          )}
          {cfg.layout == "with_link_list" && (
            <>
              {renderCards(0, 1, Card)}
              {renderCards(1, 2, Card)}
              <LinkList {...linkList} />
            </>
          )}
          {cfg.layout == "3" && (
            <>
              {renderCards(0, 1, Card)}
              {renderCards(1, 2, Card)}
              {renderCards(2, 3, Card)}
            </>
          )}
        </Grid>
      </Container>
    </>
  )
}

LatestPosts.propTypes = {
  config: PropTypes.object,
  image_media_object: PropTypes.object
}
