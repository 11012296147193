import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import Animate from "@components/animate"
import Card from "@components/card"
import Grid from "@components/grid"
import Link from "@components/link"
import LinkList from "./link-list"
import SocialNav from "./social-nav"
import SVG from "@components/svg"
import GpcBanner from "@components/gpc-banner"
import { getUserLocation } from "@root/src/utils"

import {
  copyright_container,
  footer_container,
  footer_ctas,
  footer,
  logo_with_legal_copy,
  logo,
  menus_container,
  social_copyright_container
} from "./styles.module.scss"

const Footer = ({ copyright, ctas, menus, social_links }) => {
  const [locale, setLocale] = useState()
  const [menuUpdated, setMenuUpdated] = useState(false)
  const localesArray = [
    "California",
    "Colorado",
    "Connecticut",
    "Utah",
    "Washington",
    "Nevada",
    "Florida",
    "Montana",
    "Oregon",
    "Texas"
  ]

  const gpcLocalesArray = ["California", "Colorado"]

  const privacyChoicesArray = [
    "Florida",
    "Montana",
    "Oregon",
    "Texas",
    "Connecticut",
    "Utah",
    "California",
    "Colorado"
  ]

  const [showGpcBanner, setShowGpcBanner] = useState(false)

  const isTargetedLocale = (locale) => {
    return localesArray.includes(locale)
  }

  const privacyPolicy = {
    url: "https://www.kroger.com/i/privacy-policy/",
    label: "Kroger Privacy Policy"
  }

  const waHealthUpdate = {
    url: "http://www.kroger.com/i/privacy-policy/health-data/wa",
    label: "WA Health Policy"
  }

  const yourPrivacyChoices = {
    url: "https://privacyportal.onetrust.com/webform/f95f67ef-e8ad-4274-9c69-04fd38042f86/bdcb0219-178b-4c21-845c-2c58736c7a6e",
    label: "Your Privacy Choices",
    icon: "privacy"
  }

  useEffect(() => {
    const getLocation = async () => {
      const l = await getUserLocation()
      setLocale(l)
    }
    getLocation()
  }, [])

  // prettier-ignore
  useEffect(() => {
    const more = menus.find((menu) => menu.heading === "More")
    if (!menuUpdated && locale !== undefined) {
      setMenuUpdated(true)

      // append privacy policy to the more menu
      if(!more.links.some((l) => l.label.includes("Kroger Privacy Policy"))) {
        //if nevada, append #nevada to the privacy policy url
        if(locale === "Nevada") {
          privacyPolicy.url += "#nevada"
        }
        more.links.push(privacyPolicy)
      }

      if (isTargetedLocale(locale)) {
        //append washington health update to the more menu if washington
        if(!more.links.some((l) => l.label.includes("WA Health Policy")) && locale === "Washington") {
          more.links.push(waHealthUpdate)
        }

        // append your privacy choices to the more menu if in the targeted locales
        if(!more.links.some((l) => l.label.includes("Your Privacy Choices")) && privacyChoicesArray.includes(locale)) {
          more.links.push(yourPrivacyChoices)
        }

        // check if GPC is enabled in the user's browser
        // only enabled for California and Colorado
        if (gpcLocalesArray.includes(locale) && GPCEnabledInBrowser()) {
          setShowGpcBanner(true)
        }
      }
    }
  }, [locale])

  // Function to check if GPC is enabled in the user's browser
  const GPCEnabledInBrowser = () => {
    if (typeof navigator !== "undefined" && navigator.globalPrivacyControl) {
      // Check if the globalPrivacyControl property is set to true, GPC is enabled
      return navigator.globalPrivacyControl === true
    } else {
      // If navigator is not available or globalPrivacyControl is not set, GPC is disabled
      return false
    }
  }

  return (
    <>
      {showGpcBanner && <GpcBanner />}

      <footer className={footer}>
        <div className={footer_container}>
          {ctas && (
            <Grid gutter="small" className={footer_ctas}>
              {ctas.map((cta, index) => {
                return <Card key={index} {...cta} />
              })}
            </Grid>
          )}

          <div>
            <Animate className={logo} animate="fade_in">
              <Link to="/">
                <SVG name="logo" />
              </Link>
            </Animate>

            <nav className={menus_container}>
              {menus &&
                menus.map((menu, index) => (
                  <LinkList
                    content={menu.content}
                    currentIndex={index}
                    heading={menu.heading}
                    key={index}
                    links={menu.links}
                    vertical
                  />
                ))}
            </nav>
          </div>

          <Animate className={social_copyright_container} animate="fade_in">
            <SocialNav links={social_links} />

            <div className={copyright_container}>
              <div className={logo_with_legal_copy}>
                <SVG name="logo" />
                <span>A Kroger Company</span>
              </div>

              {copyright && <small>&copy; {copyright}</small>}
            </div>
          </Animate>
        </div>
      </footer>
    </>
  )
}

Footer.propTypes = {
  /**
   * Specifies the CTA cards in the footer
   */
  ctas: PropTypes.array,
  /**
   * Copyright text.
   */
  copyright: PropTypes.string.isRequired,
  /**
   * An array of footer menu links.
   * This prop is passed to the LinkList component
   */
  menus: PropTypes.array.isRequired,
  /**
   * An array of social links
   * This prop is passed to the SocialNav component
   */
  social_links: PropTypes.array
}

Footer.defaultProps = {}

export default Footer
